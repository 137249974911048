import { JSON_DEL, JSON_GET, JSON_POST, JSON_PUT, dataCenterComAxiosGet, dataCenterComAxios } from './axiosConfig';

// 添加gs
export function apiAddGS (params: any) {
    return JSON_POST('/ultra/api/v1/gs/chairman', params);
}

// 修改gs
export function apiEditGS (params: any) {
    return JSON_PUT('/ultra/api/v1/gs/chairman', params);
}

// 删除/恢复GS
export function apiDelOrRecoverGS (params: any) {
    return JSON_DEL('/ultra/api/v1/gs/chairman', params);
}

// 删除/恢复GS
export function apiGetGSList (params: any) {
    return JSON_GET('/ultra/api/v1/gs/chairmans', params);
}

// 获取玩家详情
export function apiGetPlayerInfo (params: any) {
    return JSON_GET('/ultra/api/v1/gs/chairman/player', params);
}

// 换绑玩家
export function apiEditBind (params: any) {
    return JSON_PUT('/ultra/api/v1/gs/chairman/player', params);
}

// 绑定玩家
export function apiBindPlayer (params: any) {
    return JSON_POST('/ultra/api/v1/gs/chairman/player', params);
}

// 解绑玩家
export function apiDelPlayer (params: any) {
    return JSON_DEL('/ultra/api/v1/gs/chairman/player', params);
}

// 绑定管理列表数据
export function apiGetPlayerList (params: any) {
    return JSON_GET('/ultra/api/v1/gs/chairman/players', params);
}

// 获取gs 名称列表
export function apiGetGSNameList (params: any) {
    return JSON_GET('/ultra/api/v1/gs/chairman/names', params);
}

// 获取绑定记录  
export function apiGetBindHistories (params: any) {
    return JSON_GET('/ultra/api/v1/gs/chairman/player/histories', params);
}

// 我的收益数据
export function apiGSIncomeData (params: any) {
    return dataCenterComAxiosGet('/v1/public/gs/overview', params);
}

// 我的收益玩家列表
export function apiGSIncomePlayerList (params: any) {
    return dataCenterComAxiosGet('/v1/public/gs/playerList', params);
}

// 我的收益订单
export function apiGSIncomeOrderList (params: any) {
    return dataCenterComAxiosGet('/v1/public/gs/playerOrder', params);
}

// 获取币种列表
export function apiGSIncomeCurrentList (params: any) {
    return dataCenterComAxios('/v1/public/select/currency', params);
}

// GS数据概览
export function apiGsOverview (params: any) {
    return dataCenterComAxiosGet('/v1/public/gs/overview', params);
}

// GS收益报表
export function apiGsPlayerOrderList (params: any) {
    return dataCenterComAxiosGet('/v1/public/gs/gsList', params);
}